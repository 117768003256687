import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import { IMAGES } from '../constants/theme';

const boxWrapper = [
    {image: IMAGES.boxlog1, title:"Nutritional Guidance", content: 'Fuel your success with the right nutrition – at Body Shape Fitness, we emphasize the vital role of balanced eating to optimize your performance, recovery, and overall well-being.'},
    {image: IMAGES.boxlog2, title:"Goal-Oriented Coaching", content: 'Receive one-on-one coaching from our dedicated trainers, focusing on your unique objectives, be it weight loss, muscle gain, or overall fitness improvement.'},
    {image: IMAGES.boxlog3, title:"Personalized Training Programs", content: 'Unlock your fitness potential with customized workout plans designed specifically for your goals and preferences, guided by our experienced personal trainers'},
    {image: IMAGES.boxlog4, title:"Motivational Support", content: 'Stay inspired and on track with our motivational support system, providing encouragement, accountability, and the mindset needed to conquer your fitness journey.'},
];

const FitnessIdeas = () => {
    const [hoverBox , setHoverBox] = useState(0);
    return (
        <>
            <div className="row">
                {boxWrapper.map((item, index)=>(
                    <div className="col-xl-3 col-md-6 m-b30" key={index}>
                        <div className={`icon-bx-wraper style-1 box-hover ${ index === hoverBox ? 'active' : ''}`} 
                            onMouseEnter={()=>setHoverBox(index)}
                        >
                            <div className="icon-bx m-b30"> 
                                <span className="icon-cell">
                                    <img src={item.image} alt=""/>
                                </span>
                            </div>
                            <div className="icon-content">
                                <h5 className="dz-title m-b10"><Link to={"/services-details"}>{item.title}</Link></h5>
                                <p className="m-b25">{item.content}</p>
                                <Link to={"/services-details"} className="btn btn-primary shadow-primary btn-skew"><span>Read More</span></Link>
                            </div>
                        </div>
                    </div>
                ))}
            </div> 
        </>
    );
};

export default FitnessIdeas;